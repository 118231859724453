.container.full-width {
  max-width: none;
}

.site-header .dropzone {
  height: auto;
  margin-left: 1rem;
}

.site-main {
  min-height: 100vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.site-footer {
  margin-top: 1rem;
}

.sidebar { z-index: 10; }
.sidebar.open { padding-right: 1rem; }
.sidebar-content { overflow: hidden; }

.spread {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.select-box {
  display: block;
  margin: 0.5rem;
}

.select-box select, .select-box input {
  width: 100%;
}

.infobox {
  z-index: 10;
  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60rem;
  padding: 1rem;
  max-width: calc(100% - 4rem);
  min-height: calc(100vh - 8rem);
  background: #eee;
}

.infobox h2::before {
  content: none;
}

.infobox .toggleInfobox {
  position: absolute;
  top: 1px;
  right: 0.5rem;
  background: none;
  font-size: 32px;
  color: #333;
}

.infobox table {
  width: 100%;
  margin-bottom: 1rem;
}

.infobox table.fixed {
  table-layout: fixed;
}

.infobox td {
  padding: 0.5rem;
  word-break: break-all;
}

.infobox tr:nth-child(odd) td {
  background: #ddd;
}

.xnat {
  margin: 1rem 0;
}
