.edf-wrapper { width: 100%; }

.edf {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.range {
  position: sticky;
  top: 0;
  z-index: 2;
  user-select: none;
}
.range .dygraph-rangesel-bgcanvas {
  background-color: rgba(66, 139, 202, .5);
}

.range .dygraph-rangesel-zoomhandle {
  top: calc(50% - 8px);
  display: none;
}

.range:hover .dygraph-axis-label { display: none; }
.range:hover .dygraph-rangesel-zoomhandle { display: block; }


.hypnogram { overflow: hidden; }
.hypnogram .dygraph-rangesel-fgcanvas { top: -2px; }
.hypnogram .dygraph-rangesel-bgcanvas { background-color: white; }
.hypnogram .dygraph-rangesel-zoomhandle { display: none; }

.hypnogram .dygraph-axis-label {
  width: 53px;
  padding-top: 16px;
  line-height: 17px;
  text-align: right;
}

.graph-label {
  position: absolute;
  left: 59px;
  top: 0;
  padding-top: 1px;
  background-color: rgba(255, 255, 255, .75);
  color: #428bca;
  font-size: 14px;
  line-height: 1;
}

.graphs { flex: 1; }

.graphs > div { position: relative; }
.graphs > div:nth-child(odd) {
  background-color: #f6f6f6;
}

.graphs > div:nth-child(odd) span {
  background-color: rgba(246, 246, 246, .75);
}

.drag {
  background-color: #428bca;
}

.dygraph-ylabel,
.dygraph-axis-label.dygraph-axis-label-y {
  padding: 2px 0;
  font-size: 75%;
  color: #444;
}

/**
 * Default styles for the dygraphs charting library.
 */
.dygraph-legend {
  position: absolute;
  font-size: 14px;
  z-index: 10;
  /* width: 250px; */
  /* labelsDivWidth */
  /*
  dygraphs determines these based on the presence of chart labels.
  It might make more sense to create a wrapper div around the chart proper.
  */
  top: 0px;
  right: 0;
  padding: 2px 5px;
  background: rgba(255, 255, 255, .75);
  line-height: normal;
  text-align: left;
  overflow: hidden;
}

/* styles for a solid line in the legend */
.dygraph-legend-line {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  padding-left: 1em;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* border-bottom-color is set based on the series color */
}

/* styles for a dashed line in the legend, e.g. when strokePattern is set */
.dygraph-legend-dash {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* border-bottom-color is set based on the series color */
  /* margin-right is set based on the stroke pattern */
  /* padding-left is set based on the stroke pattern */
}

.dygraph-roller {
  position: absolute;
  z-index: 9;
}

/* This class is shared by all annotations, including those with icons */
.dygraph-annotation {
  position: absolute;
  z-index: 10;
  overflow: hidden;
}

/* This class only applies to annotations without icons */
/* Old class name: .dygraphDefaultAnnotation */
.dygraph-default-annotation {
  border: 1px solid black;
  background-color: white;
  text-align: center;
}

.dygraph-axis-label {
  position: absolute;
  font-size: 12px;
  z-index: 10;
  line-height: 1.5;
  overflow: hidden;
  color: black;
  /* replaces old axisLabelColor option */
}

.dygraph-axis-label-x {
  top: calc(50% - 8px);
  text-align: center;
}

.dygraph-title {
  font-weight: bold;
  z-index: 10;
  text-align: center;
  /* font-size: based on titleHeight option */
}

.dygraph-xlabel {
  text-align: center;
  /* font-size: based on xLabelHeight option */
}

/* For y-axis label */
.dygraph-label-rotate-left {
  text-align: center;
  transform: rotate(90deg);
}

/* For y2-axis label */
.dygraph-label-rotate-right {
  text-align: center;
  transform: rotate(-90deg);
}


/* Plotbands */

.plotband {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 0, 0, .25);
  z-index: 1000;
}

.plotband-input {
  position: absolute;
  left: 5px;
  top: 2px;
  width: 3px;
  background: transparent;
  border: 0;
  outline: none;
  font-size: 18px;
}

.plotband-left,
.plotband-right,
.plotband-move {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  line-height: 20px;
}

.plotband-left {
  right: 10px;
  cursor: w-resize;
}

.plotband-right {
  left: 10px;
  cursor: e-resize;
}
.plotband-move { cursor: ew-resize; }

.plotband-left::after { content: '◀'; }
.plotband-move::after { content: '↔'; }
.plotband-right::after { content: '▶'; }
